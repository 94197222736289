import { JSONEditor } from '@json-editor/json-editor'

document.addEventListener("turbolinks:load", () => {

  var editor_element = document.getElementById('config-editor')

  if (editor_element) {
    var editor

    editor_element.innerHTML = ''
    document.getElementById('submit').classList.remove('visually-hidden')

    JSONEditor.defaults.language = "de";
    // see a list of all values here: https://github.com/json-editor/json-editor/blob/master/src/defaults.js
    JSONEditor.defaults.languages.de = {
      error_uniqueItems: "Muß eindeutige Werte enthalten.",
      button_save: "Speichern",
      button_copy: "Kopieren",
      button_cancel: "Abbrechen",
      button_add: "Hinzufügen",
      button_delete_all: "Alle",
      button_delete_all_title: "Alle entfernen",
      button_delete_last: 'Letzten {{0}}',
      button_delete_last_title: 'Letzten {{0}} entfernen',
      button_add_row_title: '{{0}} hinzufügen',
      button_move_down_title: 'Nach unten verschieben',
      button_move_up_title: 'Nach oben verschieben',
      button_properties: 'Eigenschaften',
      button_copy_row_title: '{{0}} kopieren',
      button_delete_row_title: '{{0}} entfernen',
      button_delete_row_title_short: 'Entfernen'

    }

    editor = new JSONEditor(editor_element, {
      schema: gon.json_schema,
      theme: 'bootstrap4',
      disable_properties: true,
      disable_collapse: true,
      disable_edit_json: true,
      prompt_before_delete: false,
      display_required_only: false,
      disable_array_delete_last_row: true,
      show_errors: 'always',
      // iconlib: 'jqueryui',
      form_name_root: 'data'
    })

    // console.log(editor.getValue())
    // alert('wait0')

    if (gon.data) {
      var items = gon.data
      for(var item in items) {
        // Get a reference to a node within the editor
        // DEV_ENV root.var versus PRODUCTION_ENV config_data.var WTF!
        const item_field = editor.getEditor('data.' + item) || editor.getEditor('root.' + item);
        // // `getEditor` will return null if the path is invalid
        if (item_field) {
          item_field.setValue(items[item]);

          // console.log(item_field.getValue());
        }
      }
    }

    $('#submit').click(function() {
      // Validate the editor's current value against the schema
      const errors = editor.validate()
      var output_field = document.getElementById('infobox')
      // console.log(editor.getValue())
      // console.log(errors)
      // alert('wait!')
      if (errors.length == 0) {
        // It's valid!
        // console.log(editor.getValue())
        // alert('wait!')
        console.log('it is valid, submitting ...')
        $.ajax({
          url: gon.url,
          data: {
            data: JSON.stringify(editor.getValue())
          },
          dataType: 'json',
          type: 'PATCH',
          cache: false,
          beforeSend: function(xhr) {
            xhr.setRequestHeader(
              'X-CSRF-Token',
              $('meta[name="csrf-token"]').attr('content')
            )
          },
          success: function(data, textStatus, jqXHR) {
            // return window.location.href = gon.url;

            // var successModal = new Modal(document.getElementById('saveSuccess'))
            // successModal.show()
            let current = new Date();
            let cDate = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
            let cTime = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
            let dateTime = cDate + ' ' + cTime;

            output_field.innerHTML = '<div role="alert" class="alert alert-success alert-dismissible fade show">' + dateTime + ' - Konfiguration gespeichert.<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
          },
          error: function(xhr, textStatus, errorThrown) {
            alert("Speichern fehlgeschlagen (" + xhr.responseText + ") - Bitte überprüfen Sie Ihre Eingaben!");

          }
        })
      } else {

        var output = "<div role='alert' class='alert alert-danger alert-dismissible fade show'>Fehler:</div>\n<br/><ul class='list-group' id='editor-errors'>\n";
        errors.forEach(function(error) {
          output = output + '<li class="list-group-item"><strong class="me-2">' + error.path.replace(/data\./, '') + '</strong>' + error.message +  "</li>";
        });
        output_field.innerHTML = output + '</ul>';
        console.log(errors)
      }
      event.preventDefault()

    });
  }
});
